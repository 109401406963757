.contactus-container {
    position: absolute;
    top: 0;
    overflow: hidden;
    z-index: -100;
    width: 100%;
}

.contactUs-footer {
    background-image: url(./../../assets/vanilladream_bg_desktop_002.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}