.home-container {
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: -100;
}

.img-slide {
  opacity: 0.3;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 1;
}

.carousel-slide {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 98.5vw;
}

.home-slide {
  display: none;
}

button {
  cursor: pointer;
}

.slide-wrapper {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 100%, black 100%);
}

.slide-buttons-wrapper {
  position: absolute;
  right: 0;
  bottom: 0px;
  top: 650px;
  margin-right: 100px;
}

.prev,
.next {
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 9px;
  margin: 8px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 1s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid rgba(#ffffff, 0.3);
  border-radius: 30px;
  float: right;
}

.prev:hover,
.next:hover {
  border-right-color: #ffffff;
  border-radius: 50%;
  animation: spinner-border 1s linear forwards;
}

@keyframes spinner-border {
  0%   {border-bottom-color: #ffffff;}
  25%  {border-left-color: #ffffff;}
  50%  {border-top-color: #ffffff;}
  100% {border-color: #ffffff;}
}

.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.svg-slider {
  width: 16px;
  height: 16px;
  fill: #ffffff;
}

.slide-text {
  position: absolute;
  top: 240px;
  left: 252px;
  width: 583px;
  line-height: 80px;
  font-size: 72px;
}

.slide-goto {
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 2px;
  position: absolute;
  top: 556px;
  left: 252px;
  margin: 50px 0;
}

.ourSaSApp-column {
  height: 635px !important;
}

.ourSaSApp-text {
  padding-top: 0 !important;
}

.section-two:before {
  background: rgba(0,0,0,0.4);
}

.homefooter-image {
  background-image: url(./../../assets/vanilladream_bg_desktop_005.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
