@import './../../styles/variable.scss';

.footer-container {
    min-width: 1440px;
    width: 100%;
    background-color: var(--black);
}

.footer-wrapper {
    width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-container > .row > div {
    margin: 159px 30px;
    padding: 5px;
    font-size: 30px;
    width: 107px;
  }

.footer-text {
    font-size: 42.7px;
    line-height: 53.3px;
}

.footer-bigtext {
    font-size: 58px;
    line-height: 74.7px;
    margin: 32px 0;
}

.footer-column {
    flex: 1 0 21% !important;
}

button.getintouch {
    cursor: pointer;
    background-color: #ffffff;
    width: 225px;
    height: 64px;
    border-radius: 32px;
    font-weight: bold;
    color: var(--black);
    font-size: 18.7px;
    line-height: 32px;
    letter-spacing: 2.13px;

    &:hover {
        color: var(--primary);
    }
}

.footer-menu {
    text-decoration: none;
    font-size: 18.7px;
    line-height: 42.7px;
    letter-spacing: 2.13px;
    color: #ffffff;

    &:hover {
        color: var(--primary);
    }
}

.footer-menu > p {
    margin: 0 0 10px 0;
}

.footer-column-three > p {
    font-weight: 500;
    font-size: 28px;
    line-height: 42.7px;
    margin: 0;
}

.footer-normal-text {
    font-size: 18px;
    line-height: 32px;
}

.margin-top {
    margin-top: 43px;
}

.end-footer {
    font-size: 16px !important;
    line-height: 24pt;
    margin: -60px 30px !important;
    height: 20px;
}