.workwithus-container {
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: -100;
  width: 100%;
}

.boder-div {
  width: 80%;
  margin: 0 auto;
  display: flow-root;
}

.w-hr {
  border-bottom: 2px solid #ffffff;
  width: 80%;
  margin: 0 auto;
  opacity: 0.4;
}

.w-title {
  font-size: 36px;
  line-height: 40pt;
  font-weight: normal !important;
  color: var(--primary);
  font-weight: normal !important;
  letter-spacing: -1px;
}

.ol-div {
  margin: 50px 0 0 0;
}

.w-subtext {
  width: 80%;
  font-size: 20px !important;
  font-weight: normal;
}

.w-number {
  font-size: 90px;
  line-height: 78pt;
  font-weight: bold;
  width: 25%;
  float: left;

  p {
    margin: 0;
    text-align: right;
  }
}

.w-number#two,
.w-number#three {
  p {
    text-shadow: #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px,
      #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px;
    color:#161616;
    -webkit-font-smoothing: antialiased;
  }
}

.w-context {
  width: 65%;
  float: right;
  margin-bottom: 50px;
}
