@import './variable.scss';

@font-face {
  font-family: "DMSerifDisplay";
  src: url(./../assets/fonts/DMSerifDisplay/DMSerifDisplay-Regular.ttf);
}

@font-face {
  font-family: "AvenirNextRegular";
  src: url(./../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf);
}

.header-font {
  font-family: "DMSerifDisplay";
}

.bg-container {
  background-image: url(./../assets/vanilladream_bg_lines_01@2x.png);
  background-repeat: round;
  background-size: contain;
}

.title {
  font-size: 64px;
  line-height: 72px;
  color: #ffffff;
  margin-bottom: 80px;
}

.landing-section-text {
  // width: 519px;
  // margin: 50px 57px 0 280px; //252px
  width: 500px;
  margin: 100px 207px 0 auto;
}

button {
  cursor: pointer;
  background-color: transparent;
}

section {
  margin-top: 100px;
}

.section-wrapper {
  width: 100%;//612px;
  height: 100%;//860px;
  position: relative;
}

.row {
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
}

.img-column {
  flex: 20%;
  height: 860px;
}

.text-column {
  flex: 35%;
}

.right {
  float: right;
}

.left {
  float: left;
}

.section-right-button {
  margin-bottom: 50px;
  right: -105px;
  position: absolute;
  bottom: 0;
}


.section-left-button {
  margin-bottom: 50px;
  left: -105px;
  position: absolute;
  bottom: 0;
}

.section-left-text {
  width: 450px;
  margin: 0 170px 0 252px;
  padding-top: 115px;
}

.section-right-text {
  width: 450px;
  margin: 8px 203px 0 216px;
  padding-top: 315px;
  float: right;
}

.section-title {
  font-size: 16px;
  line-height: 24pt;
  letter-spacing: 2px;
  margin: 22px 0;
}

.line-separator {
  border-bottom: 1px solid #ffffff;
  width: 80px;
}

.section-text {
  margin: 42px 0 79px 0;
  font-size: 40px;
  line-height: 48pt;
}

.styled-text {
  color: var(--primary);
}

.section-subtext {
  font-size: 24px;
  line-height: 32pt;
}

.section-subtext > p {
  margin: 0 0;
}

.arrowdown-button {
  margin-bottom: 230px !important;
}

.column-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  width: 60%;
  // margin-left: 280px;
}

.column-item {
  flex: 0 32%;
  margin-bottom: 2%; 
}

.image-before-footer {
  width: 100%;
  height: 560px;
  opacity: 0.48;
}

.ourvanilla-image {
  background: linear-gradient(rgba(6,6,6,.4), rgba(6,6,6,.4)), url(./../assets/vanilladream_bg_005@2x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.ourfarm-image {
  background: linear-gradient(rgba(6,6,6,.24), rgba(6,6,6,.24)), url(./../assets/vanilladream_bg_008@2x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.aboutus-image {
  background: linear-gradient(rgba(6,6,6,.24), rgba(6,6,6,.24)), url(./../assets/handbook_vanilla_03_header@2x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.ourSaSApp-image {
  background-image: url(./../assets/ImagefromiOS.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 318px !important;
  height: 647px !important;
  margin: 0 0 120px 263px;
  border-radius: 25px;
}

.ourvanilla2-image {
  background: linear-gradient(rgba(6,6,6,.24), rgba(6,6,6,.24)), url(./../assets/vanilladream_bg_desktop_003.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.contactus-image {
  background: linear-gradient(rgba(6,6,6,.24), rgba(6,6,6,.24)), url(./../assets/vanilladream_bg_desktop_002.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}


.text-decoration {
  text-decoration: none;
  color: #ffffff;
}