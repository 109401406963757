.explore-section {
    width: 1032px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 56px;
    line-height: 80pt;
    color: var(--primary);
    text-align: center;
  }
  
  ol.explore-opportunities {
    list-style-type: decimal-leading-zero;
  }
  
  li {
    font-size: 48px;
    line-height: 56pt;
    margin: 32px 0 32px 100px;
    font-weight: bold;
  }

  .explore-text {
    font-size: 40px;
    line-height: 56pt;
    text-align: right;
    font-style: italic;
    font-weight: normal !important;
  }