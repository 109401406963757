.arrowdown-style {
    width: 200px;
    height: 200px;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid rgba(#ffffff, 0.4);
    // opacity: 48%;
    font-size: 16px;
    line-height: 24pt;
    letter-spacing: 2pt;
    color: #ffffff;
    font-weight: bold;

    &:hover {
        // color: var(--primary);
        background-color: #ffffff;
        opacity: 100;

        svg {
            fill: var(--primary);
        }
    }
}

// .arrowdown-center {
//     text-align: end;
// }