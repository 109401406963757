.aboutus-container {
    position: absolute;
    top: 0;
    overflow: hidden;
    z-index: -100;
    width: 100%;
}

#aboutus-section-three h1 {
    width: 700px;
    size: 56px;
    line-height: 64pt;
    margin: auto;
}

.founder-img {
    height: 400px;
    width: 300px;
}

.section-subtext > h2 {
    font-size: 32px;
    line-height: 48px;
}

.user-slide {
    display: none !important;
}