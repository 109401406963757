.goto-arrow {
    width: 64px;
    height: 64px;
    border-radius: 50px;
    opacity: 0.4;
    margin: -20px 16px;
    // background-image: url('./../../assets/svg/iconmonstr-arrow-right-thin.svg');
    background-color: transparent;
    background-repeat: no-repeat, repeat;
    background-position: center;
    border: 1px solid var(--secondary);
    // border-radius: 25px;
    // float: right;
  
    &:hover {
      background-color: var(--secondary);
      color: black;
      opacity: 100;
      
      svg {
        fill: var(--black);
      }
    }
  }
  
  svg {
    fill: var(--secondary);
  }