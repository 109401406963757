.ourSaaSApp-container {
    z-index: -100;
  }

  .ourSaaSApp-main {
    position: absolute;
    top: 0;
    z-index: -100;
  }

  .saas-header {
      width: 578px;
      margin: 40px 350px 0;
  }

  .mobile-section {
    height: 1650px;
    margin-top: -200px !important;
    position: relative;

    .section-right-text {
      padding-top: 200px;
      margin: 8px 203px 0 0px;
      width: 320px;
      height: 480px;
    }
    
    .mobile-img-column {
      margin: 160px 0 150px 330px;
      position: absolute;
      left: -15px;
      z-index: -1;
      bottom: -70px;
    }

    .mobile-circle {
      background: #191919;
      border-radius: 350px;
      width: 640px;
      height: 640px;
    }

    .mobile-handbook {
      display: inline-flex;
      float: right;
    }

    .mobile-overview {
      position: absolute;
      right: 690px;
    }

    .mobile-desc {
      position: absolute;
      right: 0;
      bottom: 220px;
    }

    .mobile-navs {
      margin-left: auto;
      position: absolute;
      bottom: 0;
      right: 156px;
    }
  }

  .handbook {
    margin: 0 360px 0 auto;
    width: 467px;
    height: 775px;
  }

  .overview {
    margin: 160px auto 0 330px;
    width: 355px;
    height: 754px;
  }

  .no-rotate {
    transform: rotate(0deg) !important;
    transition: opacity .5s,transform 1.5s cubic-bezier(.16,.97,.42,1),margin-top 2s cubic-bezier(.16,.97,.42,1);
  }

  .mobile-wrap {
    transform: translateX(-30px) rotate(-12deg)!important;
    transition: opacity .5s,transform 1.5s cubic-bezier(.16,.97,.42,1),margin-top 2s cubic-bezier(.16,.97,.42,1);
  }

  /******* LOCOMOTIVE  *****/
  html.has-scroll-smooth {
    overflow: hidden;
  }

  html.has-scroll-dragging,
  html.has-scroll-dragging * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .has-scroll-smooth body {
    overflow: auto;
  }
  .c-scrollbar {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
  }
  .c-scrollbar:hover {
    transform: scaleX(1.45);
  }
  .c-scrollbar:hover,
  .has-scroll-dragging .c-scrollbar,
  .has-scroll-scrolling .c-scrollbar {
    opacity: 1;
  }
  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #000;
    width: 7px;
    margin: 2px;
  }

  .saas-down-button {
    margin-bottom: 50px !important;
    left: -850px !important;
    position: absolute !important;
    top: 150px !important;
    margin: 0 !important;
    z-index: 1 !important;
}