.sidenav {
    height: 100%;
    width: 156px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    line-height: 60px;
    overflow-x: hidden;
    padding-top: 20px;
    border-right: 1px solid rgba(255, 255, 255, .2);
}

.logo-title {
    font-size: 12px;
    line-height: 24pt;
    letter-spacing: 1.6pt;
    margin: 0 53px;
}

.logo-container {
    position: absolute ;
    bottom: 40px;
}

.icon {
    margin: 0 20px;
    cursor: pointer;
}

.logo {
    margin: 0 66px !important;
    background-color: #ffffff;
    background: transparent;//rgba(0,0,0,.6);
    background-size: 48px 48px;
}