.arrow {
    width: 48px;
    height: 48px;
    background-color: transparent;
    background-repeat: no-repeat, repeat;
    background-position: center;
    border: 1px solid var(--secondary);
    border-radius: 25px;
    float: right;
    margin: 10px;
  
    &:hover {
      background-color: var(--secondary);
      color: black;
      opacity: 100 !important;

      svg {
        fill: var(--black);
      }
    }
  }
  
  svg {
    fill: var(--secondary);
  }