@import "./../../styles/variable.scss";
@import "./../../styles/burger";

.top-container {
  width: 100%;
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-wrapper {
  padding: 28pt 56pt;
  text-align: right;
  // position: sticky;
  // top: 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 100;
  width: 70%;
  margin-left: auto;
}

button.join-us {
  cursor: pointer;
  background-color: transparent;
  width: 85pt;
  height: 30pt;
  border-radius: 24pt;
  border: 1.2pt solid var(--secondary);
  margin: 0 25pt;
  color: var(--secondary);

  &:hover {
    background-color: var(--primary);
  }
}

.menu-button {
  display: inline-block;
  background-color: transparent;
  border: 0px solid transparent;
  width: 40px;
  height: 10pt;
  margin-left: 5pt;
}

.burger-icon {
  @include burger(30px, 1.2pt, 5px, var(--secondary));
}

.menu-button.is-active .burger-icon {
  @include burger-to-cross;
}

span {
  font-size: 12px;
  line-height: 24pt;
  letter-spacing: 1.6pt;
}

/**** NAV MENU ****/
.overlay {
  width: 0;
  height: 100%;//840px;
  overflow: hidden;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: url(./../../assets/vanilladream_bg_lines_01@2x.png);
  background-color: var(--black);
  overflow-x: hidden;
  transition: 0.5s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.overlay-content {
  width: 100%;
  text-align: left;
  margin: 120px 300px;
  width: 678px;
}

.overlay a {
  padding: 8px 25px 8px 0;
  text-decoration: none;
  font-size: 32px;
  color: var(--secondary);
  display: block;
  transition: 0.3s;
  line-height: 40pt;
  opacity: 0.4;
  width: 456px;
  line-height: 50pt;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
  opacity: 100 !important;

  .arrow {
    background: #ffffff;
    color: black;
    opacity: 100 !important;
  }

  svg {
    fill: var(--black);
    opacity: 100 !important;
  }
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 32px;
  color: var(--secondary);
  border: 0px;
  opacity: 100 !important;
}

.overlay span {
  display: inline-block;
  vertical-align: bottom;
}

.overlay-img {
  background: url("./../../assets/vanilladream_bg_desktop_005.jpg");
  background-repeat: no-repeat;
  width: 612px;
}

.bg-menu {
  width: 640px;
  height: 100%;//840px;
  overflow: hidden;
  float: right;
  background-position: center center;
  background-size: cover;
  opacity: 0.4;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

/**** *******************/
