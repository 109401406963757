@import './../../styles/variable.scss';

/*** tympanus.net form css**/
.input {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 1em;
	max-width: 350px;
	width: calc(100% - 2em);
	vertical-align: top;
}

.input__field {
	position: relative;
	display: block;
	float: right;
	padding: 0.8em;
	width: 60%;
	border: none;
	border-radius: 0;
	background: #f0f0f0;
	color: #aaa;
	font-weight: 400;
	font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
	outline: none;
}

.input__label {
	display: inline-block;
	float: right;
	padding: 0 1em;
	width: 40%;
	color: var(--primary);;
	font-weight: bold;
	font-size: 70.25%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.input__label-content {
	position: relative;
	// display: block;
	padding: 2em 0;
    width: 100%;
    font-size: 24px;
    line-height: 32pt;
    color: var(--secondary);
    opacity: 0.48;
    // margin-bottom: 10px;
}

.graphic {
	position: absolute;
	top: 0;
	left: 0;
	fill: none;
}

.icon {
	color: #ddd;
	font-size: 150%;
}

.input--nao {
	overflow: hidden;
	padding-top: 3em;
}

.input__field--nao {
	padding: 0.5em 0em 0.25em;
	width: 100%;
	background: transparent;
	color: var(--secondary);
    font-size: 1.25em;
}

.input__label--nao {
	position: absolute;
	top: 0.95em;
	font-size: 16px;
	left: 0;
	display: block;
	width: 100%;
	text-align: left;
	padding: 0em;
	pointer-events: none;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
	transition: transform 0.2s 0.15s, color 1s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.graphic--nao {
	stroke: var(--secondary);
	pointer-events: none;
	-webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
	transition: transform 0.7s, stroke 0.7s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
} 

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
	color:  var(--secondary);
	-webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    opacity: 0.48;
}

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
	stroke:  var(--primary);;
	-webkit-transform: translate3d(-66.6%, 0, 0);
	transform: translate3d(-66.6%, 0, 0);
}

/** ******************************************************** ***/

.contacform-column {
    flex: 25%;
    padding: 30px 50px;
}

button.sendMessage-button {
    cursor: pointer;
    background-color: var(--primary);
    width: 192px;
    height: 48px;
    border-radius: 24pt;
    margin: 0 5pt;
    color: var(--secondary);

    &:hover {
        background-color: transparent;
        border: 1.2pt solid var(--secondary);
        color: var(--primary);
    }
}